<script lang="ts">
    import { getContext, onMount } from 'svelte';
    import toast from 'svelte-french-toast';
    import { Input } from '$lib/components/ui/input';
    import { Label } from '$lib/components/ui/label';
    import { Button } from '$lib/components/ui/button';
    import * as Alert from '$lib/components/ui/alert';
    import * as Card from '$lib/components/ui/card';
    import { Switch } from '$lib/components/ui/switch';
    import UserDevicesList from './UserDevicesList.svelte';
    import ResendVerificationEmailBox from '../../Components/ResendVerificationEmailBox.svelte';
    import axios from 'axios';
    import type { AxiosError } from 'axios';
    import type { SettingsContext } from '@/interfaces/settings';

    const settingsContext = getContext<SettingsContext>('settingsContext');
    const { authUser, data } = settingsContext;

    let successMessage = '';
    let errorMessage = '';
    let isLoading = false;
    let password = '';
    let newPassword = '';
    let confirmPassword = '';

    $: isFormValid = password && newPassword && confirmPassword;

    async function updateSecurity(event: Event) {
        event.preventDefault();
        if (!isFormValid) return;

        isLoading = true;
        const dataToSend = { password, new_password: newPassword, confirm_password: confirmPassword };

        try {
            const response = await axios.post('/my/settings/account/save', dataToSend);
            successMessage = response.data.message;
            password = newPassword = confirmPassword = '';
            toast.success(response.data.message);
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response && axiosError.response.status === 422) {
                errorMessage = trans(axiosError.response.data.message);
            } else {
                errorMessage = trans('An unexpected error occurred');
            }
            toast.error(errorMessage);
        } finally {
            isLoading = false;
        }
    }

    async function handle2fa(checked: boolean) {
        const dataToSend = checked ? { enable_2fa: true } : {};

        try {
            const response = await axios.post('/my/settings/2fa/save', dataToSend);
            toast.success(response.data.message);
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response && axiosError.response.status === 422) {
                toast.error(axiosError.response.data.message);
            } else {
                toast.error('An unexpected error occured');
            }
        }
    }

    onMount(() => {
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['X-CSRF-TOKEN'] = document
            .querySelector('meta[name="csrf-token"]')
            ?.getAttribute('content');
    });
</script>

{#if authUser.email_verified_at === null}
    <ResendVerificationEmailBox />
{/if}

<form on:submit="{updateSecurity}" class="space-y-6">
    {#if successMessage}
        <Alert.Root variant="success">
            <Alert.Description>{successMessage}</Alert.Description>
        </Alert.Root>
    {/if}

    {#if errorMessage}
        <Alert.Root variant="destructive">
            <Alert.Description>{errorMessage}</Alert.Description>
        </Alert.Root>
    {/if}

    <div class="space-y-2">
        <Label for="password">Current Password</Label>
        <Input id="password" name="password" type="password" bind:value="{password}" required />
    </div>

    <div class="space-y-2">
        <Label for="new-password">New Password</Label>
        <Input id="new-password" name="new_password" type="password" bind:value="{newPassword}" required />
    </div>

    <div class="space-y-2">
        <Label for="confirm-password">Confirm New Password</Label>
        <Input id="confirm-password" name="confirm_password" type="password" bind:value="{confirmPassword}" required />
    </div>

    <Button type="submit" disabled="{!isFormValid || isLoading}" variant="default" class="w-full text-white">
        {#if isLoading}
            <span class="mr-2">Saving</span>
            <span class="animate-spin">&#9696;</span>
        {:else}
            Save
        {/if}
    </Button>
</form>

{#if data.allow_users_2fa_switch}
    <Card.Root class="mt-6">
        <Card.Header>
            <Card.Title>Two-Factor Authentication</Card.Title>
        </Card.Header>
        <Card.Content>
            <div class="flex items-center space-x-2">
                <Switch id="enable_2fa" checked="{authUser.enable_2fa}" onCheckedChange="{handle2fa}" />
                <Label for="enable_2fa" class="">Enable email 2FA</Label>
            </div>
            <p class="mt-2 text-sm text-muted-foreground">
                If enabled, access from new devices will require verification.
            </p>

            {#if authUser.enable_2fa}
                <div class="mt-6 space-y-4">
                    {#if data.verifiedDevicesCount}
                        <div>
                            <h6 class="mb-2 text-sm font-medium leading-none">Allowed Devices</h6>
                            <UserDevicesList type="verified" />
                        </div>
                    {/if}

                    {#if data.unverifiedDevicesCount}
                        <div>
                            <h6 class="mb-2 text-sm font-medium leading-none">Unverified Devices</h6>
                            <UserDevicesList type="unverified" />
                        </div>
                    {/if}
                </div>
            {/if}
        </Card.Content>
    </Card.Root>
{/if}
